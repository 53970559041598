<template>
  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <BaseIcon class="ic-24 primary" icon="plus-circle" />
        <h1 class="title">{{ isEdit ? 'Редактирование документа' : 'Создать документ' }}</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <form class="form-add pb-40 pos-r z0 p-20 w-50vwn" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
        <section class="form-add__section grid ggap-20">
          <div class="grid gtc-2 ggap-20 ai-fs">
            <FormInput label="Название" required placeholder="Название документа" v-model="formData.name" />
            <FormAutocomplete label="Проект" required :options="optionsProjects" v-model="formData.project_id" />
          </div>
        </section>

        <!-- Файлы -->
        <section class="form-add__section grid ggap-20 mt-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title">Файл</h2>
          </div>
          <FormSingleFileGrid
            keyName="file"
            title="Загрузите документ"
            required="true"
            v-model="document"
            acceptTypes=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.png,.gif,.zip,.rar"
          />
        </section>
      </form>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-fe ggap-20">
        <button class="btn primary" @click="submitAction">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Сохранить
        </button>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { isInvalidForm } from '@/plugins'
import { ModalWrapper, BaseIcon, FormInput, FormAutocomplete, FormSingleFileGrid, BaseLoad } from '@/components'
import templates from '@/api/modules/templates'

// Data
const store = useStore()
const route = useRoute()
const router = useRouter()
const formData = ref({})
const document = ref({})
const isLoad = ref(false)
const isEdit = route.meta.edit

// Computed
const optionsProjects = computed(() => store.getters.projects || [])

// Created
route?.params?.id && getItem()
store.dispatch('GET_PROJECTS')

// Methods
function closeAction() {
  router.replace('/templates')
}

async function getItem() {
  try {
    isLoad.value = true
    const { data } = await templates.get(route?.params?.id)
    formData.value = {
      name: data?.data?.template_name,
      project_id: optionsProjects.value?.find((el) => el?.id === data?.data?.project?.id)
    }
    document.value.file = {
      file: {
        name: data?.data?.name,
        size: data?.data?.size,
        mime: data?.data?.mime
      }
    }
    isLoad.value = false
  } catch (err) {
    console.log(err)
  }
}

async function submitAction() {
  try {
    if (isInvalidForm('form-add')) return
    isLoad.value = true
    const formDataFinal = new FormData()

    formDataFinal.append('name', formData.value?.name)
    formDataFinal.append('project_id', formData.value?.project_id?.value)
    document?.value?.file?.id && formDataFinal.append('document', document?.value?.file?.file)

    if (isEdit) {
      const { data } = await templates.update(route?.params?.id, formDataFinal)
      store.commit('templates/CHANGE_ITEM', data?.data)
    } else {
      const { data } = await templates.create(formDataFinal)
      store.commit('templates/PUSH_ITEMS', [data?.data])
    }
    await store.dispatch('templates/GET_ROWS')
    closeAction()
  } catch (err) {
    console.log(err)
  } finally {
    isLoad.value = false
  }
}
</script>
